import { createFromIconfontCN } from "@ant-design/icons";
import dayjs from "dayjs";
import { history } from "umi";
import ATools from 'apipost-tools';
import { Message } from 'adesign-react';

/**
 * 基于JSON序列化进行对象克隆
 */
export const jsonClone = (source: any): any => {
  return JSON.parse(JSON.stringify(source));
};

/**
 * 时间戳转换，如果传入的不是number类型，则返回原值
 */
export const convertTimestampToDateString = (
  timestamp: number | any,
  pattern: string = "YYYY-MM-DD HH:mm:ss",
): string | any => {
  if (typeof timestamp !== "number") {
    return timestamp;
  }
  return dayjs(timestamp).format(pattern);
};

//获取url链接中的参数
export function getQueryVariable(variable: string) {
  if (typeof window === "undefined") {
    return null;
  }

  const url = window.location.search;
  const reg = new RegExp(`(^|&)${variable}=([^&]*)(&|$)`);
  const result = url.slice(1).match(reg);
  return result ? decodeURIComponent(result[2]) : null;
}

// 跳转到新的页面，支持在新窗口打开；
export function linkToUrl(url: string, target: string = "") {
  if (typeof window === "undefined" || !url) {
    return;
  }
  let isCurDomain = /^\/[^\/]/.test(url);

  if (target) {
    window.open(url, target);
  } else {
    if (isCurDomain || url === "/") {
      // 如果跳转的页面，是当前页面，则直接return
      if (history?.location?.pathname === url) {
        return;
      }
      history.push(url);
    } else {
      location.href = url;
    }
  }
}

export const IconFont = createFromIconfontCN({
  scriptUrl: ["//at.alicdn.com/t/font_2798674_0mwvhzhphvce.css"],
});


/**
 * 编辑器内容格式化
 */
export const EditFormat = (value: any) => {
  return ATools.beautifyRaw(value);
};

// 复制文本到剪切板
export const copyStringToClipboard = (str: string, showMessage: Boolean = true) => {
  const clipboardObj = navigator.clipboard;
  // 不支持Clipboard对象直接报错
  if (clipboardObj) {
    // 读取内容到剪贴板
    clipboardObj.writeText(str).then(
      () => {
        showMessage && Message('success', '已成功将内容复制到剪贴板', 0.8);
      },
      () => {
        try {
          const textarea = document.createElement('textarea');
          document.body.appendChild(textarea);
          // 隐藏此输入框
          textarea.style.position = 'fixed';
          textarea.style.clip = 'rect(0 0 0 0)';
          textarea.style.top = '10px';
          // 赋值
          textarea.value = str;
          // 选中
          textarea.select();
          // 复制
          document.execCommand('copy', true);
          // 移除输入框
          document.body.removeChild(textarea);
        } catch (error) {
          showMessage && Message('error', '复制失败。');
        }
      }
    );
  } else {
    try {
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      // 隐藏此输入框
      textarea.style.position = 'fixed';
      textarea.style.clip = 'rect(0 0 0 0)';
      textarea.style.top = '10px';
      // 赋值
      textarea.value = str;
      // 选中
      textarea.select();
      // 复制
      document.execCommand('copy', true);
      // 移除输入框
      document.body.removeChild(textarea);
      if (str) {
        showMessage && Message('success', '已成功将内容复制到剪贴板', 0.8);
      }
    } catch (error) {
      showMessage && Message('error', '复制失败。');
    }
  }
};

export const getMarked = (md: string | undefined) => {
  if (!md) return ''
  marked?.setOptions({
    renderer: new marked.Renderer(),
    highlight: function (code) {
      // 为页面所有pre code 进行高亮
      return hljs.initHighlighting(code);
    },
    breaks: true,
    sanitize: true
  });
  return marked(md).replace(/<pre>/g, "<pre class='hljs'>");
}

// 获取到salt， 用于获取token
export const getSaltCode = () => {
  const pathname = window.location.pathname
  const regex = /\/([\w-]+)$/;
  const match = pathname.match(regex);
  const result = match ? match[1] : null;
  return result;

}
