import { getSaltCode } from "@/utils";
import { getCookie } from "@/utils/cache";
import HYRequest from "./request";
import { BASE_URL, TIME_OUT } from "./request/config";

const hyRequest = new HYRequest({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    interceptors: {
        requestInterceptor: (config: any) => {
            const salt = getSaltCode();
            const Authorization = getCookie(salt);
            if (config.headers && Authorization) {
                config.headers.Authorization = Authorization;
            }

            return config;
        },
        requestInterceptorCatch: (err: any) => {
            return err;
        },
        responseInterceptor: (res: any) => {
            return res;
        },
        responseInterceptorCatch: (err: any) => {
            return err;
        },
    },
});

export default hyRequest;
