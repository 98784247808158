// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/preview","parentId":"@@/global-layout","id":"1"},"2":{"path":"docs/preview/:id/:salt","parentId":"@@/global-layout","id":"2"},"3":{"path":"/preview/:id/:salt","parentId":"@@/global-layout","id":"3"},"4":{"path":"/*","parentId":"@@/global-layout","id":"4"},"5":{"path":"/pageFail","parentId":"@@/global-layout","id":"5"},"6":{"path":"/passwordPage/:salt","parentId":"@@/global-layout","id":"6"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import( './EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__404__index" */'@/pages/404/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__PageFail__index" */'@/pages/PageFail/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__PasswordPage__index" */'@/pages/PasswordPage/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/data/docker/jenkins/jenkins_data_20230525/workspace/apipost-document-sharing/online-apipost-document-sharing/src/layouts/index.tsx')),
},
  };
}
