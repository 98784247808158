import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router';
import history from  '@/config/history'
import API from '@/common/middleware';
import reducer from './reducers';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const initialState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

const middlewares = [
  thunk,
  API,
  routerMiddleware(history)
];
const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
  );
export default store;
