/**
 * @file reduce
 * @author wushaobo
 */
import { combineReducers } from "redux";
import apiDebug from "./apiDebug";
import doc from "./doc";
import global from "./global";
import project from "./project";

export default combineReducers({
    doc,
    apiDebug,
    project,
    global,
});
