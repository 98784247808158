import { DEFAULT_RUNTIME_HEADERS } from "./constant/global";

const NAMESPACE = "global";

const initialState = {
    runtimeHeaders: DEFAULT_RUNTIME_HEADERS,
};

// action名称
enum actionTypes {
    updateRuntimeHeader = "updateRuntimeHeader",
}

export const projectReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case `${NAMESPACE}/${actionTypes.updateRuntimeHeader}`:
            return {
                ...state,
                runtimeHeaders: payload,
            };

        default:
            return state;
    }
};

export default projectReducer;
